<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員等級管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="isFetching">
      <b-card>
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">{{ `已擁有${customerLevel.name}會員等級的會員` }}</h4>

        <div class="d-flex justify-content-end">
          <div class="list-group-item d-flex align-items-center border-0">
            <b-button
              variant="info"
              class="flex-shrink-0 mb-2 mb-xl-0"
              :to="{ name: 'CustomerLevelsLog', params: { id: $route.params.id } }"
            >
              異動紀錄
            </b-button>
          </div>
          <div class="list-group-item d-flex align-items-center border-0">
            <b-button
              variant="primary"
              class="flex-shrink-0 mb-2 mb-xl-0"
              @click="showImportModal"
            >
             批次匯入 
            </b-button>
          </div>
          <div class="row">
            <StaffAddCustomer
              ref="staffAddCustomer"
              add-button-text="加入會員"
              @bind="bindCustomer"
            ></StaffAddCustomer>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="customers"
              :fields="fields"
            >
              <template #cell(avatar_url)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
                    <b-avatar
                      :src="data.item.avatar_url"
                      variant="secondary"
                      size="2rem"
                    ></b-avatar>
                  </router-link>
                </div>
              </template>
              <template #cell(name)="data">
                <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >{{ data.item.name | hiddenString(20) }}</router-link>
              </template>
              <template #cell(action)="data">
                <div class="d-flex justify-content-center">
                  <b-button variant="inverse-danger" size="sm" @click="unbindCustomer(data.item)">
                    移除會員
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchCustomers"
          ></b-pagination>
        </div>
      </b-card>
    </b-overlay>
    <b-modal id="modal-batch-assign" title="批次匯入">
      <b-overlay :show="showLoadingUpload">
        <template v-if="batchAssignStep == 1">
          <div class="form-group">
            <p class="text-danger">依 LINE ID、手機號碼 順序比對會員</p>
            <b-form-file plain v-model="batchAssignFile"></b-form-file>
            <b-button
              class="mt-2"
              size="sm"
              variant="outline-primary"
              href="/excel/batch_assign_redeem_code.xlsx"
            >
              <i class="fa fa-file-text"></i>下載範例檔案
            </b-button>
          </div>
        </template>
        <template v-else-if="batchAssignStep == 2">
          <div class="form-group">
            <p>
              上傳資料筆數：{{ importCustomerPreview.total_member_count }}<br/>
              對應會員數量：{{ importCustomerPreview.valid_member_count }}<br/>
            </p>
            <p v-if="importCustomerPreview.valid_member_count != importCustomerPreview.total_member_count" class="text-danger">
              上傳資料數量與對應會員數量不同，請問是否確定匯入
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-danger" size="sm" class="float-right" @click="cancel()">
          取消
        </b-button>
        <b-button
          v-if="batchAssignStep == 1"
          size="sm"
          variant="outline-primary"
          @click="previewCustomer"
        >
          下一步 
        </b-button>
        <b-button v-if="batchAssignStep == 2" size="sm" variant="outline-primary" @click="goBackBatchAssign">回上一步</b-button>
        <b-button
          v-if="batchAssignStep == 2"
          size="sm"
          variant="outline-primary"
          @click="importCustomer"
        >
          確定匯入
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import customerLevelApi from "@/apis/customer-levels";
import moment from "moment";
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  components: { BAvatar, StaffAddCustomer },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isFetching: false,
      customerLevel: {
        name: "",
      },
      showLoadingUpload: false,
      batchAssignFile: null,
      batchAssignStep: 1,
      importCustomerPreview: {
        total_member_count: 0,
        valid_member_count: 0,
      },
      customers: [],
      fields: [
        { key: "avatar_url", label: "" },
        { key: "name", label: "LINE 名稱" },
        { key: "outer_code", label: "平台ID" },
        { key: "mobile_phone", label: "手機" },
        { key: "email", label: "Email" },
        {
          key: "birthday",
          label: "生日",
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD') : '';
          }
        },
        { key: 'action', label: '操作' },
    ],
    };
  },

  mounted() {
    this.fetchCustomerLevel()
    this.fetchCustomers()
  },

  methods: {
    showBatchAssignModal() {
      this.batchAssignStep = 1;
      this.$bvModal.show('modal-batch-assign')
    },
    async fetchCustomerLevel() {
      const { data } = await customerLevelApi.show(
        this.$route.params.id
      );
      this.customerLevel = data.data
    },
    async fetchCustomers() {
      try {
        this.isFetching = true;
        const { data } = await customerLevelApi.getCustomers(this.$route.params.id, {
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.customers = data.data
        this.totalRows = data.meta.total
      } catch (e) {
        console.log(e)
      }
      this.isFetching = false
    },
    async bindCustomer(customer) {
      this.$swal({
        title: "確定要加入會員嗎",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>消費者姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認加入",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.doBindCustomer(customer)
          } catch (e) {
            console.log(e)
          } finally {
            this.$refs.staffAddCustomer.refreshSearch()
            this.$refs.staffAddCustomer.closeModal()
          }
        }
      })
    },
    async doBindCustomer(customer) {
      this.isFetching = true
      try {
        await customerLevelApi.bindCustomer(this.$route.params.id, customer.id)
        await this.fetchCustomers()
      } catch (error) {
        console.error(error);

        this.$swal(
          "加入失敗",
          `${customer.name} 加入失敗`,
          "warning"
        );
      }
      this.isFetching = false
    },
    async unbindCustomer(customer) {
      const result = await this.$swal({
        title: "移除會員",
        text: `確定要將 ${customer.name} 移除嗎?`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "移除",
        reverseButtons: true,
      })
      if (! result.value) {
        return
      }

      this.isFetching = true
      try {
        await customerLevelApi.unbindCustomer(this.$route.params.id, customer.id)
        await this.fetchCustomers()
      } catch (error) {
        console.error(error);

        this.$swal(
          "解除失敗",
          `${customer.name} 解除失敗`,
          "warning"
        );
      }
      this.isFetching = false
    },
    showImportModal() {
      this.batchAssignStep = 1;
      this.$bvModal.show('modal-batch-assign')
    },
    importFailHandler(e) {
      console.error(e);
      if (e.response.status === 422 && e.response.data.message[0]) {
        this.$swal.fire({
          title: "檔案內容有誤",
          type: "error",
          text: e.response.data.message[0] ?? null,
        });
      } else {
        this.$swal.fire({
          title: "上傳失敗",
          type: "error",
        });
      }
    },
    goBackBatchAssign() {
      this.batchAssignFile = null
      this.batchAssignStep = 1
    },
    async previewCustomer() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.batchAssignFile);

      try {
        let { data } = await customerLevelApi.importCustomerPreview(this.$route.params.id, formData);
        this.importCustomerPreview.total_member_count = data.data.total_members;
        this.importCustomerPreview.valid_member_count = data.data.total_valid_members;

        this.batchAssignStep = 2;
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
      }
    },
    async importCustomer() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.batchAssignFile);

      try {
        await customerLevelApi.importCustomer(this.$route.params.id, formData);
        this.$swal.fire({ title: "上傳成功", type: "success" });

        this.fetchCustomers()
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-batch-assign");
      }
    },
  },
};
</script>

<style></style>
